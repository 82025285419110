import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src265819322/src/gatsby-theme-theme-ui/node_modules/@smerth/gatsby-theme-mdx/src/components/layouts/LayoutMdxDefault.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Documentation`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      