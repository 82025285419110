/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { LayoutNoSidebar } from "@smerth/gatsby-theme-theme-ui"
// import Seo from "../../../../components/Seo"

function LayoutMdxDefault(props) {
  const { className, children } = props

  return (
    <LayoutNoSidebar className={className} fluid={false}>
      <Container variant="constrained" py="md">
        {/*   TODO: SEO */}
        {/* <Seo title={pageTitle} description={summary} article={true} /> */}
        {children}
      </Container>
    </LayoutNoSidebar>
  )
}

export default LayoutMdxDefault
